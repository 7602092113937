import React, { useState } from "react";
import {
  navigationBar,
  navInner,
  logoWrapper,
  navList,
  menuBarsWrappers,
  navMenu,
  navMenuVisible,
  movilMenuList,
  logoMovilMenuWrapper,
  companyInfoMovilMenu,
  linkStyle,
  navLink,
  navLinkActive,
} from "./navbar.module.css";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const MovilMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navBarClick = (e) => setIsVisible(!isVisible);

  return (
    <>
      <div className={`${navMenu} ${isVisible ? navMenuVisible : ""}`}>
        <div className={logoMovilMenuWrapper}>
          <StaticImage
            alt="Korall Medics logo"
            src="../../images/korall-medics-logo.svg"
            layout="constrained"
          />
        </div>
        <ul className={movilMenuList}>
          <li>
            <Link to="/" className={linkStyle}>
              Nosotros
            </Link>
          </li>
          <li>
            <Link to="/productos" className={linkStyle}>
              Productos
            </Link>
          </li>
          <li>
            <Link to="/contacto" className={linkStyle}>
              Contacto
            </Link>
          </li>
        </ul>
        <div className={companyInfoMovilMenu}>
          <p>Av. Hidalgo #1731 tercer piso</p>
          <p>Col. Ladrón de Guevara</p>
          <p>C.P. 44600</p>
          <p>Guadalajara, Jalisco, México</p>
          <p>(33) 2267 2429</p>
          <p>(33) 2267 2430</p>
          <p>info@korall-medics.com</p>
        </div>
      </div>
      <div className={menuBarsWrappers}>
        {isVisible ? (
          <StaticImage
            onClick={navBarClick}
            alt="Menu bars icon"
            src="../../images/close-icon.svg"
            layout="constrained"
          />
        ) : (
          <StaticImage
            onClick={navBarClick}
            alt="Menu bars icon"
            src="../../images/menu-bars.svg"
            layout="constrained"
          />
        )}
      </div>
    </>
  );
};

const Navbar = () => {
  return (
    <nav className={navigationBar}>
      <div className={navInner}>
        <MovilMenu />
        <div className={logoWrapper} id="logoNavbar">
          <StaticImage
            alt="Korall Medics logo"
            src="../../images/korall-medics-logo.svg"
            layout="constrained"
          />
        </div>
        <ul className={navList}>
          <li>
            <Link to="/" className={navLink} activeClassName={navLinkActive}>
              Nosotros
            </Link>
          </li>
          <li>
            <Link
              to="/productos"
              className={navLink}
              activeClassName={navLinkActive}
            >
              Productos
            </Link>
          </li>
          <li>
            <Link
              to="/contacto"
              className={navLink}
              activeClassName={navLinkActive}
            >
              Contacto
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
