import React from "react";
import { Helmet } from "react-helmet";
import { mainStyle } from "./layout.module.css";

import Navbar from "../Navbar";
import WhatsAppButton from "../WhatsAppButton";

const Layout = ({ children }) => {
  return (
    <main className={mainStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Korall Medics</title>
      </Helmet>
      <Navbar />
      {children}
      <WhatsAppButton />
    </main>
  );
};

export default Layout;
