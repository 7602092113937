// extracted by mini-css-extract-plugin
export var linkStyle = "navbar-module--linkStyle--3W913";
export var navigationBar = "navbar-module--navigationBar--3NmfU";
export var navInner = "navbar-module--navInner--u5BHJ";
export var logoWrapper = "navbar-module--logoWrapper--1uI-L";
export var navList = "navbar-module--navList--2MKIQ";
export var navLink = "navbar-module--navLink--1Ei0E";
export var navLinkActive = "navbar-module--navLinkActive--2eftx";
export var menuBarsWrappers = "navbar-module--menuBarsWrappers---kRu8";
export var navMenu = "navbar-module--navMenu--2mD4l";
export var navMenuVisible = "navbar-module--navMenuVisible--3cdYD";
export var movilMenuList = "navbar-module--movilMenuList--1fpZM";
export var logoMovilMenuWrapper = "navbar-module--logoMovilMenuWrapper--hHqeB";
export var companyInfoMovilMenu = "navbar-module--companyInfoMovilMenu--3X9lx";