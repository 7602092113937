import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { buttonStyle, waLogoWrapper } from "./whatsAppButton.module.css";

const WhatsButton = () => {
  return (
    <button className={buttonStyle}>
      <a href="https://wa.me/523333921713" target="_blank">
        <div className={waLogoWrapper}>
          <StaticImage
            alt="Whats app logo"
            height="100%"
            src="../../images/whatsapp-logo.svg"
            layout="constrained"
          />
        </div>
      </a>
    </button>
  );
};

export default WhatsButton;
